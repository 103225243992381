import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';

// Constants
const OPENAI_API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';
const OPENAI_MODEL = 'gpt-4o-mini';
const MAX_TOKENS = 200;
const MAX_MESSAGES_PER_MODEL = 3;

const CHARACTERS = {
  squid: { emoji: '🦑', color: '#800080', name: 'Squid - Calamar' },
  frog: { emoji: '🐸', color: '#008000', name: 'Frog - Rana' },
  eagle: { emoji: '🦅', color: '#8B4513', name: 'Eagle - Aguila' },
  shark: { emoji: '🦈', color: '#0000FF', name: 'Shark - Tiburon' },
  lion: { emoji: '🦁', color: '#FFA500', name: 'Lion - Leon' },
  penguin: { emoji: '🐧', color: '#000000', name: 'Penguin - Pingüino' },
  elephant: { emoji: '🐘', color: '#808080', name: 'Elephant - Elefante' },
  alien: { emoji: '👽', color: '#32CD32', name: 'Alien - Extraterrestre' }
};

const CharacterSelection = ({ selectedCharacters, onCharacterSelect }) => {
  return (
    <motion.div 
      className="character-selection"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <a href="https://zienbastian.dev" className="return-link">
        Volver a zienbastian.dev
      </a>
      <h2>Elige 2 personajes</h2>
      <div className="character-grid">
        {Object.entries(CHARACTERS).map(([key, character]) => (
          <motion.button
            key={key}
            className={`character-button ${selectedCharacters.includes(key) ? 'selected' : ''}`}
            onClick={() => onCharacterSelect(key)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={selectedCharacters.length === 2 && !selectedCharacters.includes(key)}
          >
            <span className="character-emoji">{character.emoji}</span>
            <span className="character-name">{character.name}</span>
          </motion.button>
        ))}
      </div>
      <p className="selection-info">
        {selectedCharacters.length === 0 && "Selecciona 2 personajes para iniciar la conversación."}
        {selectedCharacters.length === 1 && "Selecciona otro personaje"}
        {selectedCharacters.length === 2 && "¡Genial! Ahora ingresa un tema del cual discutirán estos personajes"}
      </p>
    </motion.div>
  );
};

export default function App() {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [conversationStarted, setConversationStarted] = useState(false);
  const [selectedCharacters, setSelectedCharacters] = useState([]);

  const generateResponse = useCallback(async (prompt, character) => {
    setIsTyping(true);
    try {
      const response = await axios.post(
        OPENAI_API_ENDPOINT,
        {
          model: OPENAI_MODEL,
          messages: [
            { role: 'system', content: `You are a ${character}. Respond as if you were this character. Limit your response to 200 tokens. Always answer in Spanish and stay in character.` },
            { role: 'user', content: prompt }
          ],
          max_tokens: MAX_TOKENS
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsTyping(false);
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error generating response:', error);
      setIsTyping(false);
      return 'Lo siento, hubo un error al generar la respuesta.';
    }
  }, []);

  const handleCharacterSelection = (character) => {
    setSelectedCharacters(prevSelected => {
      if (prevSelected.includes(character)) {
        return prevSelected.filter(c => c !== character);
      } else if (prevSelected.length < 2) {
        return [...prevSelected, character];
      }
      return prevSelected;
    });
  };

  const handleUserInput = async (event) => {
    event.preventDefault();
    if (!userInput.trim() || selectedCharacters.length !== 2) return;

    setConversationStarted(true);
    const topic = userInput;
    setUserInput('');

    const [character1, character2] = selectedCharacters;
    const response1 = await generateResponse(`Discute el tema: ${topic}`, character1);
    const response2 = await generateResponse(`Responde al mensaje del ${character1}: ${response1}`, character2);

    setMessages([
      { text: response1, character: character1 },
      { text: response2, character: character2 }
    ]);
  };

  const continueConversation = useCallback(async () => {
    if (messages.length >= MAX_MESSAGES_PER_MODEL * 2) return;

    const lastMessage = messages[messages.length - 1];
    const nextCharacter = selectedCharacters[messages.length % 2];
    const prompt = `Responde a: "${lastMessage.text}"`;

    const response = await generateResponse(prompt, nextCharacter);
    setMessages(prevMessages => [...prevMessages, { text: response, character: nextCharacter }]);
  }, [messages, generateResponse, selectedCharacters]);

  useEffect(() => {
    if (conversationStarted && messages.length > 0 && messages.length < MAX_MESSAGES_PER_MODEL * 2) {
      continueConversation();
    }
  }, [conversationStarted, messages, continueConversation]);

  return (
    <div className="app">
      <CharacterSelection
        selectedCharacters={selectedCharacters}
        onCharacterSelect={handleCharacterSelection}
      />
      <form onSubmit={handleUserInput} className="user-input">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Ingresa un tema para discutir"
          disabled={conversationStarted || selectedCharacters.length !== 2}
        />
        <motion.button 
          type="submit" 
          disabled={conversationStarted || selectedCharacters.length !== 2}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Iniciar Conversación
        </motion.button>
      </form>
      <div className="conversation">
        <AnimatePresence>
          {messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </AnimatePresence>
        {isTyping && <Typing even={messages.length % 2 === 0} />}
      </div>
    </div>
  );
}

function Typing({ even }) {
  return (
    <motion.div
      className={`typing ${even ? 'is-right' : 'is-left'}`}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
    >
      <div className="dots">
        <div />
        <div />
        <div />
      </div>
    </motion.div>
  );
}

function Message({ message }) {
  const characterInfo = CHARACTERS[message.character];
  return (
    <motion.div
      className={`message ${message.character}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="avatar">{characterInfo.emoji}</div>
      <div className="text" style={{ backgroundColor: characterInfo.color }}>{message.text}</div>
    </motion.div>
  );
}